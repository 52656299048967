import React from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarCheck } from '@fortawesome/free-solid-svg-icons'

const variantSize = {
  lg: "font-size: 1.2rem;",
  md: "font-size: 1rem;",
  sm: "font-size: 0.8rem;"
};

const ItemDate = styled.p`
  color: ${props => props.theme.grey300};
  margin-bottom: 0;
  ${props => variantSize[props.size || 'sm']}
`

export default function PostDate(props) {
  const { children } = props
  return (<ItemDate {...props}>
    <FontAwesomeIcon icon={faCalendarCheck} size="1x" className="mr-1" />{children}
  </ItemDate>)
}
