import styled from "styled-components"

const Tag = styled.div`
  font-size: 0.7rem;
  background-color: ${props => props.theme.secondary400};
  padding: 0.3rem 0.75rem 0.3rem 0.75rem;
  border-radius: 0.3rem;
`

export default Tag
